<template>
  <div>

    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="row mb-5">
<!--            <div class="form-group col-md-4 mt-2 mb-2">-->
<!--              <label for="supplier_id">{{ $t('supplier') }}</label>-->
<!--              <multiselect v-model="supplier"-->
<!--                           :placeholder="$t('supplier')"-->
<!--                           label="full_name"-->
<!--                           track-by="id"-->
<!--                           :options="suppliers"-->
<!--                           :multiple="false"-->
<!--                           :taggable="true"-->
<!--                           :show-labels="false"-->
<!--                           :show-no-options="false"-->
<!--                           :show-no-results="false">-->
<!--              </multiselect>-->
<!--            </div>-->
<!--            <div class="form-group col-md-4 mt-2 mb-2">-->
<!--              <label for="brand_id">{{ $t('brand') }}</label>-->
<!--              <multiselect-->
<!--                  id="brand_id"-->
<!--                  v-model="brand_id"-->
<!--                  :placeholder="$t('items.brand')"-->
<!--                  label="name"-->
<!--                  track-by="id"-->
<!--                  :options="brands"-->
<!--                  :multiple="false"-->
<!--                  :taggable="true"-->
<!--                  :show-labels="false"-->
<!--                  :show-no-options="false"-->
<!--                  :show-no-results="false">-->
<!--              </multiselect>-->
<!--            </div>-->
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="order_by">{{ $t('stocks_report.order_by') }}</label>
              <select name="" id="order_by" v-model="filters.order_by" class="custom-select">
                <option value="product_name">{{ $t('stocks_report.product_name') }}</option>
                <option value="stock_level_descending">{{ $t('stocks_report.stock_level_descending') }}</option>
                <option value="stock_level_ascending">{{ $t('stocks_report.stock_level_ascending') }}</option>
              </select>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="order_by">{{ $t('estimated_inventory.calculate_using') }}</label>
              <select name="" id="calculate_using" v-model="filters.calculate_using" class="custom-select">
                <option value="average_buy_price">{{ $t('estimated_inventory.average_buy_price') }}</option>
                <option value="buy_price">{{ $t('estimated_inventory.buy_price') }}</option>
              </select>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('items.category') }}</label>
              <treeselect
                  :options="categories"
                  :load-options="loadOptions"
                  :multiple="true"
                  :value="filters.f_category_ids"
                  @input="updateValue"
                  :searchable="true"
                  :show-count="true"
                  :no-children-text="$t('No_sub_options')"
                  :no-options-text="$t('No_options_available')"
                  :no-results-text="$t('No_results_found')"
                  :placeholder="$t('Select')">
              </treeselect>
            </div>

<!--            <div class="form-group col-md-4 mt-2 mb-2">-->
<!--              <label for="f_status">{{ $t('stocks_report.inventory') }}</label>-->
<!--              <multiselect v-model="inventories_ids"-->
<!--                           :placeholder="$t('stocks_report.inventory')"-->
<!--                           label="name"-->
<!--                           track-by="id"-->
<!--                           :options="inventories"-->
<!--                           :multiple="false"-->
<!--                           :taggable="true"-->
<!--                           :show-labels="false"-->
<!--                           :show-no-options="false"-->
<!--                           :show-no-results="false">-->
<!--              </multiselect>-->
<!--            </div>-->

            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('customer_transactions_report.status') }}</label>
              <multiselect
                  v-model="status_ids"
                  :placeholder="$t('customer_transactions_report.status')"
                  label="title"
                  track-by="id"
                  :options="status_list"
                  :multiple="true"
                  @input="getIds('status')"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('customer_transactions_report.branches') }}</label>
              <multiselect v-model="branches_ids"
                           :placeholder="$t('customer_transactions_report.branches')"
                           label="name"
                           track-by="id"
                           :options="branches"
                           :multiple="true"
                           @input="getIds('branches')"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="inventory_id">{{ $t('inventory_transaction_report.inventory') }}</label>
              <multiselect v-model="inventories_ids"
                           :placeholder="$t('inventory_transaction_report.inventory')"
                           label="name"
                           track-by="id"
                           :options="inventories"
                           @input="getIds('inventories')"
                           :multiple="true"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="item_id">{{ $t('product') }}</label>
              <div class="d-flex search-item-group">
                <select v-model="f_by" class="custom-select" name="" style="width: 100px;">
                  <option selected value="name">{{ $t('items.name') }}</option>
                  <option value="sku_code">{{ $t('items.sku_code') }}</option>
                </select>
                <multiselect id="item_id"
                             v-model="item"
                             :internal-search="false"
                             :multiple="false"
                             :options="items"
                             :label="f_by?f_by:'sku_code'"
                             :placeholder="$t('product')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             track-by="id"
                             @search-change="getItems(f_by, $event)">
                </multiselect>
              </div>
            </div>


            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('inventory_transaction_report.from_date') }}</label>
              <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('inventory_transaction_report.to_date') }}</label>
              <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
            </div>

            <div class="col-md-6 mt-2 mb-2 pt-10">
              <div>
                <b-button-group>
                  <b-button  @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_date')}}</b-button>
                  <b-button  @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_month')}}</b-button>
                  <b-button  @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_year')}}</b-button>
                  <b-button  @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>
                  <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                </b-button-group>

              </div>
              <div class="mt-5">
                <b-button-group>
                  <b-button  @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.yesterday')}}</b-button>
                  <b-button  @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_month')}}</b-button>
                  <b-button  @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_year')}}</b-button>
                </b-button-group>

              </div>
            </div>

            <div class="form-group d-inline-flex col-md-6 mt-10">
              <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{ $t('display_report') }}</b-button>
              <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}</b-button>

            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>


    <div class="card card-custom mt-5">

      <div class="card-body">

        <!-- Filter -->
        <div>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <b-tabs content-class="mt-3">
              <b-tab active :title="$t('daily_brief_report_invoices.summary')">
                <div class="row">
                  <!-- <div class="col-12 mt-10">
                      <hr>
                  </div> -->
                  <div class="col-12 mt-10" id="summaryTableTitle">
                    <h4 class="text-center">{{ $t('estimated_inventory.estimated_inventory') }}</h4>
                    <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }} {{ filters.to_date }}</span></h5>
                  </div>
                  <div class="col-12 mt-10 d-flex justify-content-end">
                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
<!--                      <download-excel-->
<!--                          class="dropdown-item"-->
<!--                          :fetch="dataReport"-->
<!--                          :fields="json_fields"-->
<!--                          :name="$t('estimated_inventory.estimated_inventory')+'.xls'">-->
<!--                        <i class="la la-file-excel"></i>{{ $t('excel') }}-->
<!--                      </download-excel>-->
                      <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTable')">
                        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="getAllDataForExportSummery('sectionForExport', 'excel')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                      </button>
                    </b-dropdown>
                  </div>
                  <div class="col-12 mt-10">
                    <div class="table-responsive">
                      <table class="table table-bordered text-center" id="summaryTable">
                        <thead>
                        <!-- <tr>
                            <th></th>
                            <th colspan="5">{{$t('inventory_transaction_report.inward')}}</th>
                            <th colspan="5">{{$t('inventory_transaction_report.outward')}}</th>
                            <th></th>
                        </tr> -->
                        <tr>
                          <th>#</th>
                          <th>{{ $t('estimated_inventory.code') }}</th>
                          <th>{{ $t('estimated_inventory.product_name') }}</th>
                          <th>{{ $t('estimated_inventory.qty') }}</th>
                          <th>{{ $t('estimated_inventory.current_retail_price') }}</th>
                          <th>{{ $t('estimated_inventory.average_retail_price') }}</th>
                          <th>{{ $t('estimated_inventory.expected_total_retail_price') }}</th>
                          <th>{{ $t('estimated_inventory.total_buy_price') }}</th>
                          <th>{{ $t('estimated_inventory.expected_profit') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, index) in data.data" :key="index">
                          <td>{{ index+1 }}</td>
                          <td>{{ row.code }}</td>
                          <td>{{ row.product_name }}</td>
                          <td>{{ row.qty }}</td>
                          <td>{{ row.current_retail_price }}</td>
                          <td>{{ row.average_retail_price }}</td>
                          <td>{{ row.expected_total_retail_price }}</td>
                          <td>{{ row.total_buy_price }}</td>
                          <td>{{ row.expected_profit }}</td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr v-if="data.total">
                          <td colspan="6" style="text-align: start;">{{ $t('total') }}</td>
                          <td>{{ data.total.sum_expected_total_retail_price }}</td>
                          <td>{{ data.total.sum_total_buy_price }}</td>
                          <td>{{ data.total.sum_expected_profit }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 text-center" v-if="page">
                      <button class="btn btn-warning" @click="loadMore">
                        <p class="mb-0">
                          <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                          {{ $t('load_more') }}
                        </p>
                      </button>
                    </div>
                  </div>
                </div>

              </b-tab>
              <b-tab :title="$t('daily_brief_report_invoices.details')">
                <div class="row">
                  <!-- <div class="col-12 mt-10">
                      <hr>
                  </div> -->
                  <div class="col-12 mt-10" id="summaryTableTitle">
                    <h4 class="text-center">{{ $t('estimated_inventory.estimated_inventory') }}</h4>
                    <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }} {{ filters.to_date }}</span></h5>
                  </div>
                  <div class="col-12 mt-10 d-flex justify-content-end">
                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
<!--                      <download-excel-->
<!--                          class="dropdown-item"-->
<!--                          :fetch="dataReport"-->
<!--                          :fields="json_fields"-->
<!--                          :name="$t('estimated_inventory.estimated_inventory')+'.xls'">-->
<!--                        <i class="la la-file-excel"></i>{{ $t('excel') }}-->
<!--                      </download-excel>-->
                      <button class="dropdown-item" @click="convertTableToExcelReport('DetailsTable')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printData('DetailsTable')">
                        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="getAllDataForExportDetails('sectionForExportDetails', 'excel')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                      </button>
                    </b-dropdown>
                  </div>
                  <div class="col-12 mt-10">
                    <div class="table-responsive">
                      <table class="table table-bordered text-center" id="DetailsTable">
                        <thead>
                        <!-- <tr>
                            <th></th>
                            <th colspan="5">{{$t('inventory_transaction_report.inward')}}</th>
                            <th colspan="5">{{$t('inventory_transaction_report.outward')}}</th>
                            <th></th>
                        </tr> -->
                        <tr>
                          <th>#</th>
                          <th>{{ $t('estimated_inventory.code') }}</th>
                          <th>{{ $t('estimated_inventory.product_name') }}</th>
                          <th>{{ $t('estimated_inventory.qty') }}</th>
                          <th>{{ $t('estimated_inventory.current_retail_price') }}</th>
                          <th>{{ $t('estimated_inventory.average_retail_price') }}</th>
                          <th>{{ $t('estimated_inventory.expected_total_retail_price') }}</th>
                          <th>{{ $t('estimated_inventory.total_buy_price') }}</th>
                          <th>{{ $t('estimated_inventory.expected_profit') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(row, _index) in dataDetails.data">
                          <tr>
                            <td style="text-align: start;">{{ row.branch }}</td>
                            <td colspan="8" style="text-align: start;">{{ row.inventory }}</td>
                          </tr>
                          <tr v-for="(_row, index) in row.items" :key="'dv'+index+''+_index">
                            <td>{{ index+1 }}</td>
                            <td>{{ _row.code }}</td>
                            <td>{{ _row.product_name }}</td>
                            <td>{{ _row.qty }}</td>
                            <td>{{ _row.current_retail_price }}</td>
                            <td>{{ _row.average_retail_price }}</td>
                            <td>{{ _row.expected_total_retail_price }}</td>
                            <td>{{ _row.total_buy_price }}</td>
                            <td>{{ _row.expected_profit }}</td>
                          </tr>
                          <tr>
                            <td colspan="6" style="text-align: start;">{{ $t('total') }}</td>
                            <td>{{ row.sum_expected_total_retail_price }}</td>
                            <td>{{ row.sum_total_buy_price }}</td>
                            <td>{{ row.sum_expected_profit }}</td>
                          </tr>
                        </template>
                        </tbody>
                        <tbody>
                        <tr v-if="dataDetails.total">
                          <td colspan="6" style="text-align: start;">{{ $t('total') }}</td>
                          <td>{{ dataDetails.total.sum_expected_total_retail_price }}</td>
                          <td>{{ dataDetails.total.sum_total_buy_price }}</td>
                          <td>{{ dataDetails.total.sum_expected_profit }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 text-center" v-if="page">
                      <button class="btn btn-warning" @click="loadMore">
                        <p class="mb-0">
                          <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                          {{ $t('load_more') }}
                        </p>
                      </button>
                    </div>
                  </div>
                </div>

<!--                <div class="export-container">-->
<!--                  <div class="section-for-export">-->

<!--                  </div>-->
<!--                </div>-->
<!--                <div class="selector-export" id="selectorExport"></div>-->
              </b-tab>
            </b-tabs>


          </div>
        </div>
        <!-- End Filter -->

      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered text-center" id="sectionForExport">
          <thead>
          <!-- <tr>
              <th></th>
              <th colspan="5">{{$t('inventory_transaction_report.inward')}}</th>
              <th colspan="5">{{$t('inventory_transaction_report.outward')}}</th>
              <th></th>
          </tr> -->
          <tr>
            <th>#</th>
            <th>{{ $t('estimated_inventory.code') }}</th>
            <th>{{ $t('estimated_inventory.product_name') }}</th>
            <th>{{ $t('estimated_inventory.qty') }}</th>
            <th>{{ $t('estimated_inventory.current_retail_price') }}</th>
            <th>{{ $t('estimated_inventory.average_retail_price') }}</th>
            <th>{{ $t('estimated_inventory.expected_total_retail_price') }}</th>
            <th>{{ $t('estimated_inventory.total_buy_price') }}</th>
            <th>{{ $t('estimated_inventory.expected_profit') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in dataList.data" :key="index">
            <td>{{ index+1 }}</td>
            <td>{{ row.code }}</td>
            <td>{{ row.product_name }}</td>
            <td>{{ row.qty }}</td>
            <td>{{ row.current_retail_price }}</td>
            <td>{{ row.average_retail_price }}</td>
            <td>{{ row.expected_total_retail_price }}</td>
            <td>{{ row.total_buy_price }}</td>
            <td>{{ row.expected_profit }}</td>
          </tr>
          </tbody>
          <tbody>
          <tr v-if="dataList.total">
            <td colspan="6" style="text-align: start;">{{ $t('total') }}</td>
            <td>{{ dataList.total.sum_expected_total_retail_price }}</td>
            <td>{{ dataList.total.sum_total_buy_price }}</td>
            <td>{{ dataList.total.sum_expected_profit }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered text-center" id="sectionForExportDetails">
          <thead>
          <!-- <tr>
              <th></th>
              <th colspan="5">{{$t('inventory_transaction_report.inward')}}</th>
              <th colspan="5">{{$t('inventory_transaction_report.outward')}}</th>
              <th></th>
          </tr> -->
          <tr>
            <th>#</th>
            <th>{{ $t('estimated_inventory.code') }}</th>
            <th>{{ $t('estimated_inventory.product_name') }}</th>
            <th>{{ $t('estimated_inventory.qty') }}</th>
            <th>{{ $t('estimated_inventory.current_retail_price') }}</th>
            <th>{{ $t('estimated_inventory.average_retail_price') }}</th>
            <th>{{ $t('estimated_inventory.expected_total_retail_price') }}</th>
            <th>{{ $t('estimated_inventory.total_buy_price') }}</th>
            <th>{{ $t('estimated_inventory.expected_profit') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, _index) in dataDetailsList.data">
            <tr>
              <td style="text-align: start;">{{ row.branch }}</td>
              <td colspan="8" style="text-align: start;">{{ row.inventory }}</td>
            </tr>
            <tr v-for="(_row, index) in row.items" :key="'dv'+index+''+_index">
              <td>{{ index+1 }}</td>
              <td>{{ _row.code }}</td>
              <td>{{ _row.product_name }}</td>
              <td>{{ _row.qty }}</td>
              <td>{{ _row.current_retail_price }}</td>
              <td>{{ _row.average_retail_price }}</td>
              <td>{{ _row.expected_total_retail_price }}</td>
              <td>{{ _row.total_buy_price }}</td>
              <td>{{ _row.expected_profit }}</td>
            </tr>
            <tr>
              <td colspan="6" style="text-align: start;">{{ $t('total') }}</td>
              <td>{{ row.sum_expected_total_retail_price }}</td>
              <td>{{ row.sum_total_buy_price }}</td>
              <td>{{ row.sum_expected_profit }}</td>
            </tr>
          </template>
          </tbody>
          <tbody>
          <tr v-if="dataDetailsList.total">
            <td colspan="6" style="text-align: start;">{{ $t('total') }}</td>
            <td>{{ dataDetailsList.total.sum_expected_total_retail_price }}</td>
            <td>{{ dataDetailsList.total.sum_total_buy_price }}</td>
            <td>{{ dataDetailsList.total.sum_expected_profit }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="selector-export" id="selectorExport"></div>

    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'InventoryValue'"></report-main-header>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-inventory-transaction-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/estimated-inventory-report',
      mainRouteDetails: '/reports/estimated-grouped-inventory-report',
      mainRouteDependency: 'base/dependency',
      suppliers: [],
      data: [],
      dataList: [],
      dataDetails: [],
      dataDetailsList: [],
      brands: [],
      categories: [],

      filters: {

        supplier_id: null,
        brand_id: null,
        order_by: null,
        // category_id: null,
        // inventory_id: null,
        calculate_using: null,
        from_date: null,
        to_date: null,

        item_id: null,
        f_branches_ids: [],
        f_inventories_ids: [],
        f_status_ids: [],
        f_category_ids: [],
        period: null,
        range: null,
        f_time_period: 0,
        financial_year: 0,
      },
      brand_id: null,
      supplier: null,


      item: null,

      items: [],
      branches_ids: [],
      inventories_ids: [],
      status_ids: [],
      category_ids: [],

      branches: [],
      status_list: [],
      inventories: [],

      period_date: ['day','week','month','year'],
      period_numbers: {
        day: 31,
        week: 4,
        month: 12,
        year: 10,
      },
      period_counter: [],
      currency_default_id: null,
      branch_default: null,
      inner_period: null,

      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,
      f_by: 'name',
    }
  },
  watch: {
    brand_id: function (val) {
      if (val) {
        this.filters.brand_id = val.id;
      } else {
        this.filters.brand_id = null;
      }
    },
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },
    item: function (val) {
      if (val && val.id) {
        this.filters.item_id = val.id;
      } else {
        this.filters.item_id = null;
      }
    },
    // inventory: function (val) {
    //   if (val && val.id) {
    //     this.filters.inventory_id = val.id;
    //   } else {
    //     this.filters.inventory_id = null;
    //   }
    // }
  },
  computed: {

    json_fields: function () {
      let fields = {};
      fields[this.$t('estimated_inventory.code')] = 'code';
      fields[this.$t('estimated_inventory.product_name')] = 'product_name';
      fields[this.$t('estimated_inventory.qty')] = 'qty';
      fields[this.$t('estimated_inventory.current_retail_price')] = 'current_retail_price';
      fields[this.$t('estimated_inventory.average_retail_price')] = 'average_retail_price';
      fields[this.$t('estimated_inventory.expected_total_retail_price')] = 'expected_total_retail_price';
      fields[this.$t('estimated_inventory.total_buy_price')] = 'total_buy_price';
      fields[this.$t('estimated_inventory.expected_profit')] = 'expected_profit';

      return fields;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.store_reports"), route: '/store/reports'}, {title: this.$t("MENU.estimated_inventory")}]);

    this.getSuppliers();
    this.getBrands();
    this.getCategories();
    this.getInventories();

    this.getBranches();
    this.getStatus();
  },
  methods: {
    ...cssStypeForPrintReport,
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getData();
      this.getDataDetails();
    },

    doFilter() {
      this.page = 1;
      this.getData();
      this.getDataDetails();
    },
    resetFilter() {

      this.filters.supplier_id = null;
      this.filters.brand_id = null;
      this.brand_id = null;
      this.supplier = null;
      // this.inventory = null;
      this.filters.order_by = null;
      // this.filters.inventory_id = null;
      this.filters.from_date = null;
      this.filters.calculate_using = null;
      this.filters.to_date = null;

      this.item = null;

      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.financial_year = 0;
      this.filters.f_branches_ids = [];
      this.filters.f_status_ids = [];
      this.filters.f_inventories_ids = [];
      this.filters.f_category_ids = [];

      this.branches_ids = []
      this.status_ids = [];
      this.inventories_ids = [];
      this.category_ids = [];

      this.status_ids = this.status_list.filter((row)=>row.id == 2 || row.id == 5);
      this.filters.f_status_ids = [];
      this.getIds('status');

      this.inner_period = null;

      this.getData();
      this.getDataDetails();

    },

    getIds(related_with){

      switch (related_with) {
        case 'branches':
          this.filters.f_branches_ids = this.branches_ids.map((row) => row.id);
          break;
        case 'status':
          this.filters.f_status_ids = this.status_ids.map((row) => row.id);
          break;
        case 'inventories':
          this.filters.f_inventories_ids = this.inventories_ids.map((row) => row.id);
          break;
      }

    },
    changeFinancialYear(){
      if (this.filters.financial_year == 1)
        this.filters.financial_year = 0;
      else
        this.filters.financial_year = 1;
    },
    changeIntervalType(action_type, range = null){
      if (this.filters.period == action_type){
        this.filters.period = null;
        this.filters.f_time_period = 0;
      }else{
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

      // this.filters.range = range;
      // if (range == 1){
      //   this.inner_period = 'last_'+action_type;
      //   if (action_type == 'year')
      //     this.filters.range = this.$moment().subtract(1, 'year').year();
      // }else{
      //   this.inner_period = action_type;
      // }


      // this.period_counter = [];
      // if (this.filters.period){
      //   let _counter = this.period_numbers[this.filters.period];
      //   if (this.filters.period == 'year') {
      //     let _current_year = new Date().getFullYear();
      //     this.period_counter.push(parseInt(_current_year));
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(parseInt(_current_year) - cc);
      //     }
      //   }else {
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(cc);
      //     }
      //   }
      //
      // }else {
      //   this.period_counter = [];
      // }

    },

    dataReport() {
      this.setReportLog('excel', 'estimated inventory');
      return this.data;
    },
    getData() {
      ApiService.get(this.mainRoute, {params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => {
        this.data = response.data.data;
      });
    },
    getDataDetails() {
      ApiService.get(this.mainRouteDetails, {params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => {
        this.dataDetails = response.data.data;
      });
    },
    getSuppliers() {
      ApiService.get(this.mainRouteDependency + "/suppliers").then((response) => {
        this.suppliers = response.data.data;
      });
    },
    updateValue(value) {
      this.filters.f_category_ids = value
    },
    loadOptions() {
    },
    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    getBrands() {
      ApiService.get(this.mainRouteDependency + "/brands").then((response) => {
        this.brands = response.data.data;
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/sales_status").then((response) => {
        this.status_list = response.data.data;
        let _status = response.data.data.filter((row)=>row.id == 2 || row.id == 5 );
        if(_status){
          this.status_ids.push(..._status);
          this.getIds('status');
        }
      });
    },
    getAllDataForExportSummery(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataList = response.data.data;

        if (_export == 'pdf' || _export == 'print')
          this.printData(tableId, _export);
        if (_export == 'excel')
          this.convertTableToExcelReport(tableId);
      });
    },
    getAllDataForExportDetails(tableId, _export) {
      ApiService.get(this.mainRouteDetails, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataDetailsList = response.data.data;

        if (_export == 'pdf' || _export == 'print')
          this.printData(tableId, _export);
        if (_export == 'excel')
          this.convertTableToExcelReport(tableId);
      });
    },
    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'estimated inventory');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.estimated_inventory') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },

    printData(tableId) {
      this.setReportLog('pdf', 'estimated inventory');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
